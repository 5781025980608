
import {ComponentPublicInstance, computed, defineComponent, reactive, ref, watch} from 'vue';
import UITextSelect from "@/components/UI/UITextSelect.vue";
import UITextDropdown from "@/components/UI/UITextSelect.vue";
import moment from 'moment-timezone';
export default defineComponent({
  emit: ['selected'],
  components:{
    'ui-text-select':UITextSelect,
  },
  setup(_props, { emit }) {
    const hours = [
      {id: 0, label: '00'},
      {id: 1, label: '01'},
      {id: 2, label: '02'},
      {id: 3, label: '03'},
      {id: 4, label: '04'},
      {id: 5, label: '05'},
      {id: 6, label: '06'},
      {id: 7, label: '07'},
      {id: 8, label: '08'},
      {id: 9, label: '09'},
      {id: 10, label: '10'},
      {id: 11, label: '11'},
      {id: 12, label: '12'},
      {id: 13, label: '13'},
      {id: 14, label: '14'},
      {id: 15, label: '15'},
      {id: 16, label: '16'},
      {id: 17, label: '17'},
      {id: 18, label: '18'},
      {id: 19, label: '19'},
      {id: 20, label: '20'},
      {id: 21, label: '21'},
      {id: 22, label: '22'},
      {id: 23, label: '23'},
    ];
    const mins = [
      { id: 0, label: '00' },
      { id: 1, label: '01' },
      { id: 2, label: '02' },
      { id: 3, label: '03' },
      { id: 4, label: '04' },
      { id: 5, label: '05' },
      { id: 6, label: '06' },
      { id: 7, label: '07' },
      { id: 8, label: '08' },
      { id: 9, label: '09' },
      { id: 10, label: '10' },
      { id: 11, label: '11' },
      { id: 12, label: '12' },
      { id: 13, label: '13' },
      { id: 14, label: '14' },
      { id: 15, label: '15' },
      { id: 16, label: '16' },
      { id: 17, label: '17' },
      { id: 18, label: '18' },
      { id: 19, label: '19' },
      { id: 20, label: '20' },
      { id: 21, label: '21' },
      { id: 22, label: '22' },
      { id: 23, label: '23' },
      { id: 24, label: '24' },
      { id: 25, label: '25' },
      { id: 26, label: '26' },
      { id: 27, label: '27' },
      { id: 28, label: '28' },
      { id: 29, label: '29' },
      { id: 30, label: '30' },
      { id: 31, label: '31' },
      { id: 32, label: '32' },
      { id: 33, label: '33' },
      { id: 34, label: '34' },
      { id: 35, label: '35' },
      { id: 36, label: '36' },
      { id: 37, label: '37' },
      { id: 38, label: '38' },
      { id: 39, label: '39' },
      { id: 40, label: '40' },
      { id: 41, label: '41' },
      { id: 42, label: '42' },
      { id: 43, label: '43' },
      { id: 44, label: '44' },
      { id: 45, label: '45' },
      { id: 46, label: '46' },
      { id: 47, label: '47' },
      { id: 48, label: '48' },
      { id: 49, label: '49' },
      { id: 50, label: '50' },
      { id: 51, label: '51' },
      { id: 52, label: '52' },
      { id: 53, label: '53' },
      { id: 54, label: '54' },
      { id: 55, label: '55' },
      { id: 56, label: '56' },
      { id: 57, label: '57' },
      { id: 58, label: '58' },
      { id: 59, label: '59' },



    ]
    const timeData = reactive({
      hour: 0,
      min: 0
    });
    const time = computed(() => `${hours.find(h => h.id === timeData.hour)?.label ?? '--'}:${mins.find(h => h.id === timeData.min)?.label ?? '--'}`);
    watch(() => ({...timeData}), (val: any) => {
      if (val) {
        emit('selected', time.value);
      }
    });
    const hoursRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const minsRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    // const selectedHour = computed(() => hours[selectedHourIndex.value]);
    // const selectedHourIndex = ref(0);
    // const selectedMinute = ref(0);
    // const selectedMinuteFormatted = computed(() => {
    //   if (selectedMinute.value > 9) {
    //     return selectedMinute.value.toString();
    //   }
    //   return ('0' + selectedMinute.value).slice(0, 2);
    // });
    // const time = computed(() => `${hours[selectedHourIndex.value]}:${selectedMinuteFormatted.value}`);
    // const navigateHours = (inc: 1 | -1) => {
    //   if (inc === -1 && selectedHourIndex.value === 0) {
    //     selectedHourIndex.value = hours.length -1;
    //   } else if (inc === 1 && selectedHourIndex.value === hours.length -1) {
    //     selectedHourIndex.value = 0;
    //   } else {
    //     selectedHourIndex.value += inc;
    //   }
    //     emit('selected', time.value);
    // };
    // const navigateMinutes = (inc: 1 | -1) => {
    //   if (inc === 1 && selectedMinute.value === 59) {
    //     selectedMinute.value = 0;
    //   } if (inc === -1 && selectedMinute.value === 0) {
    //     selectedMinute.value = 59;
    //   } else {
    //     selectedMinute.value += inc;
    //   }
    //     emit('selected', time.value);
    // };

    return {
      timeData,
      hours,
      hoursRef,
      mins,
      minsRef,
      moment,
      // selectedHour,
      // selectedMinuteFormatted,
      // navigateMinutes,
      // navigateHours
    };
  }
});
