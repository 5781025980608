
import {computed, defineComponent, onMounted, Ref, ref} from 'vue';
import moment from 'moment-timezone';

export interface IDay {
  date: string;
  dayOfWeek: number;
  month: number;
  day: number;
  year: number;
  timestamp: number;
  label: string;
}

export interface IDayMin {
  timestamp?: number;
  label?: string;
}

export default defineComponent({
  name: 'Calendar',
  emits:['selected'],
  props: {
    defaultDate: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const dayLabels = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
    // today
    const today = ref({
      date: moment().format(),
      month: +moment().format('MM'),
      year: +moment().format('YYYY'),
      day: +moment().format('DD')
    });
    // navigation date
    const navigationDate = ref(moment().format());
    const firstDayOfMonth = computed(() => {
      const date = moment.utc(navigationDate.value).startOf('month').format();
      const dayNumber = +moment.utc(date).format('DD');
      const dayOfWeek = +moment.utc(date).isoWeekday() - 1;
      return { date, dayNumber, dayOfWeek };
    });
    const lastDayOfMonth = computed(() => {
      const date = moment.utc(navigationDate.value).endOf('month').format();
      const dayNumber = +moment.utc(date).format('DD');
      const dayOfWeek = +moment.utc(date).isoWeekday() - 1;
      return { date, dayNumber, dayOfWeek };
    });
    const actualDate = computed(() => {
      const [y, m, d] = moment.utc(navigationDate.value)
          .format('YYYY-MM-DD')
          .split('-');
      const label = moment.utc(navigationDate.value).format('MMMM').toUpperCase();
      return { year: +y, month: +m, day: +d, label };
    });
    // month
    const calculatedMonth: Ref<Array<IDay[]>> = ref([]);
    const calculateMonth = () => {
      const month = [];
      for (let i = 0; i < lastDayOfMonth.value.dayNumber; i++) {
        const date = moment.utc(firstDayOfMonth.value.date).add(i, 'days').format();
        const dayOfWeek = moment.utc(date).isoWeekday() - 1;
        const [y, m, d] = moment.utc(date).format('YYYY-MM-DD').split('-');
        const timestamp = +moment.utc(date).format('x');
        month.push({ date, dayOfWeek, month: +m, year: +y, day: +d, timestamp, label: moment.utc(date).format('DD MMMM YYYY') });
      }
      const firstDayweekOfMonth: number = month[0].dayOfWeek;
      for (let j = 0; j < firstDayweekOfMonth; j++) {
        const prev = j + 1;
        const date = moment.utc(firstDayOfMonth.value.date)
            .subtract(prev, 'days')
            .format();
        const dayOfWeek = moment.utc(date).isoWeekday() - 1;
        const [y, m, d] = moment.utc(date).format('YYYY-MM-DD').split('-');
        const timestamp = +moment.utc(date).format('x');
        month.unshift({ date, dayOfWeek, month: +m, year: +y, day: +d, timestamp, label: moment.utc(date).format('DD MMMM YYYY') });
      }
      const lastDayweekOfMonth: number = month[month.length - 1].dayOfWeek;
      for (let k = 0; k < 7; k++) {
        if (k > lastDayweekOfMonth) {
          const l = k - lastDayweekOfMonth;
          const date = moment.utc(lastDayOfMonth.value.date)
              .add(l, 'days')
              .format();
          const dayOfWeek = moment.utc(date).isoWeekday() - 1;
          const [y, m, d] = moment.utc(date).format('YYYY-MM-DD').split('-');
          const timestamp = +moment.utc(date).format('x');
          month.push({ date, dayOfWeek, month: +m, year: +y, day: +d, timestamp, label: moment.utc(date).format('DD MMMM YYYY') });
        }
      }
      let m: Array<IDay[]> = [];
      let w: IDay[] = [];
      month.forEach((el) => {
        w.push(el);
        if (el.dayOfWeek === 6) {
          m.push(w);
          w = [];
        }
      });
      calculatedMonth.value = m;
    };
    // add month
    const nextMonth = () => {
      const next = moment(navigationDate.value).add(1, 'month').format();
      navigationDate.value = next;
      calculateMonth();
    };
    // reduce month
    const previousMonth = () => {
      const previous = moment(navigationDate.value)
          .subtract(1, 'month')
          .format();
      navigationDate.value = previous;
      calculateMonth();
    };
    calculateMonth();
    
    const setToday = (p: any, format?: string) => {
      const d = moment(p.date, format).utc();
      today.value.date = d.format();
      today.value.month =  +d.format('MM');
      today.value.year =  +d.format('YYYY');
      today.value.day = +d.format('DD');
    };

    const onSelect = (p: any) => {
      setToday(p);
      emit('selected', today.value);
    };

    onMounted(() => {
      if (props.defaultDate) {
        navigationDate.value = moment(props.defaultDate,'DD-MM-YYYY' ).format();
        calculateMonth();
        setToday({date: props.defaultDate }, 'DD-MM-YYYY');
      } else {
        emit('selected', today.value);
      }
      
    });
    
    return {
      today,
      dayLabels,
      actualDate,
      nextMonth,
      previousMonth,
      calculateMonth,
      calculatedMonth,
      onSelect
    };
  }
});
