import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative flex items-center cursor-pointer text-gray-500" }
const _hoisted_2 = { class: "bg-red-500 text-white text-xs text-right absolute px-2 font-light absolute top-0 right-0 pointer-events-none rounded-bl-md" }
const _hoisted_3 = {
  key: 1,
  class: "absolute left-1 right-1 border-green-200 border-2 bg-white shadow-lg rounded",
  style: {"top":"60%","z-index":"999","max-height":"140px","overflow-y":"scroll"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("input", {
      ref: "inputRef",
      type: "text",
      class: `rounded-sm ${
        _ctx.disabled ? 'text-gray-600 bg-gray-200' : 'text-black'
      } px-2 py-1.5 font-regular ring-2 ${_ctx.ring} transition-all duration-500 min-w-full focus:ring-green-800 block mb-3`,
      placeholder: _ctx.placeholder,
      value: _ctx.value,
      onInput: _cache[1] || (_cache[1] = (event) => _ctx.emitTimeout(event.target.value.toUpperCase())),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlurFn && _ctx.onBlurFn(...args))),
      onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onFocusFn && _ctx.onFocusFn(...args))),
      disabled: _ctx.disabled
    }, null, 42, ["placeholder", "value", "disabled"]),
    (_ctx.value !== null)
      ? (_openBlock(), _createBlock("p", {
          key: 0,
          class: `absolute -top-2 left-0 ${_ctx.labelColor} text-white text-xs px-2 rounded-md shadow-xs`
        }, _toDisplayString(_ctx.placeholder), 3))
      : _createCommentVNode("", true),
    _createVNode("p", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1),
    (_ctx.options.length && _ctx.isFocus)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createBlock("p", {
              class: "px-4 py-2 hover:bg-gray-50 transition-all",
              key: option,
              onClick: ($event: any) => (_ctx.selectedValue(option))
            }, _toDisplayString(option), 9, ["onClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}