
import UICalendar from "@/components/UI/UICalendar.vue";
import UITime from "@/components/UI/UITime.vue";
import {computed, defineComponent, ref} from "vue";
import UIButton from "@/components/UI/UIButton.vue";

export default defineComponent({
  components: {
    'ui-button': UIButton,
    'ui-calendar': UICalendar,
    'ui-time': UITime
  },
  props: {
    canCleanDate: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const selectedDate = ref('');
    const selectedTime = ref('');
    const fullDate = computed(() => `${selectedDate.value} ${selectedTime.value}`);
    const onSelectDate = (date: { date: string; month: number; year: number; day: number }) => {
      selectedDate.value = date.date.split('T')[0].split('-').reverse().join('-');
    };
    const onSelectedTime = (hour: string) => {
      selectedTime.value = hour;
    };

    return {
      onSelectDate,
      onSelectedTime,
      fullDate,
    };
  }
});
