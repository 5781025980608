
import { inputValidation } from '@/utils';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { ComponentPublicInstance, computed, defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    placeholder: {
      type: String,
      default: 'placeholder'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    upperCase: {
      type: Boolean,
      default: true
    },
    searchType: {
      type: String,
      default: 'DESTINO'
    },
    validations: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    validationValues: {
      type: Array,
      default: () => []
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const isFocus = ref(false);
    const onFocusFn = () => {
      isFocus.value = true;
      if (props.value.toString().length > 2) {
        clearTimeout(timeout.value);
        apiRequest(props.value+'');
      }
    };
    const onBlurFn = () => {
      clearTimeout(timeout.value);
      if (isFocus.value) {
        setTimeout(() => {
          isFocus.value = false;
          options.value = [];
          validationFn();
        }, 350);
      }
    };
    const isValid = ref(true);
    const errorMessage = ref('');
    const ring = computed(() => (isValid.value ? 'ring-green-200' : 'ring-red-500'));
    const labelColor = computed(() => {
      if (isFocus.value) return 'bg-green-800';
      return isValid.value ? 'bg-green-500' : 'bg-red-500';
    });
    const options = ref<string[]>([]);
    const isLoading = ref(false);
    const emitValue = (evt: any) => {
      props.upperCase && (evt = evt.toString().toUpperCase());
      emit('update:value', evt);
      validationFn();
    };
    const timeout = ref<any>(null);
    const apiRequest = (evt: string) => {
      isLoading.value = true;
      if (!evt) {
        options.value = [];
      } else {
        makeRequest<string[]>(METHODS.GET, `/guia-customs?regex=${evt}&type=${props.searchType}`, true).then((result) => {
          isLoading.value = false;
          if (result.ok && result.payload) {
            options.value = result.payload;
          } else {
            options.value = [];
          }
        });
      }
    };
    const emitTimeout = (evt: any) => {
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        emitValue(evt);
        apiRequest(evt);
      }, 250);
    };
    const selectedValue = (val: string) => {
      emitValue(val);
      options.value = [];
    };

    const validationFn = () => {
      isValid.value = true;
      if (props.validations.length === 0) return true;
      props.validations.forEach((v: string) => {
        const validation = inputValidation(props.value+'', v, props.validationValues);
        isValid.value = validation.valid;
        errorMessage.value = validation.errorMessage;
      });
      if (isValid.value) errorMessage.value = '';
      if (!isValid.value) console.log(props.placeholder);
      return isValid.value;
    };

    const inputRef = ref<ComponentPublicInstance<HTMLInputElement> | null>(null);

    return {
      isFocus,
      isValid,
      errorMessage,
      labelColor,
      emitValue,
      inputRef,
      ring,
      emitTimeout,
      options,
      onFocusFn,
      onBlurFn,
      selectedValue,
      validationFn
    };
  }
});
