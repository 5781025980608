
import { inputValidation } from '@/utils';
import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch
} from 'vue';

export default defineComponent({
  name: 'UITextarea',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Escribir mensaje...'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const areaRef = ref<ComponentPublicInstance<HTMLTextAreaElement> | null>(null);
    const areaHeight = ref(48);
    watch(
      () => props.value,
      () => {
        areaHeight.value = areaRef.value?.scrollHeight || 48;
      }
    );
    onMounted(async () => {
      nextTick();
      areaHeight.value = areaRef.value?.scrollHeight || 48;
    });
    //
    const isValid = ref(true);
    const errorMessage = ref('');
    const ring = computed(() => {
      if (isFocus.value) return 'ring-green-800';
      return isValid.value ? 'ring-green-200' : 'ring-red-500';
    }
    );
    const isFocus = ref(false);
    const validationFn = () => {
      isFocus.value = false;
      isValid.value = true;
      if (!props.required) return true;
      if (isValid.value) errorMessage.value = '';
      const validation = inputValidation(props.value, 'required');
      isValid.value = validation.valid;
      errorMessage.value = validation.errorMessage;
      return isValid.value;
    };
    const onBlur = () => {
      isFocus.value = false;
      validationFn();
    };
    const labelColor = computed(() => {
      if (isFocus.value) return 'bg-green-800';
      return isValid.value ? 'bg-green-200' : 'bg-red-500';
    });
    onMounted(() => {
      areaRef.value && (areaRef.value.disabled = props.disabled);
    });
    watch(() => props.disabled, (val) => {
      areaRef.value && (areaRef.value.disabled = val);
    });
    return {
      areaRef,
      areaHeight,
      isValid,
      errorMessage,
      ring,
      validationFn,
      isFocus,
      labelColor,
      onBlur
    };
  }
});
