
import { ComponentPublicInstance, computed, defineComponent, nextTick, onMounted, reactive, Ref, ref, watch } from 'vue';
import UITextInput from '@/components/UI/UITextInput.vue';
import UITextDropdown from '@/components/UI/UITextSelect.vue';
import UITextarea from '@/components/UI/UITextarea.vue';
import { canchaState } from '@/store/cancha.store';
import {
  comunasState,
  canchasState,
  especiesState,
  factorCortezaState,
  intervencionesState,
  procedenciasState,
  productosState,
  unidadMedidasState,
  zonasState,
  estadoMaderasState,
} from '@/store/mantenedores.store';
import { SelectItem } from '@/models/SelectItem';
import moment from 'moment-timezone';
import { makeRequest, METHODS } from '@/utils/apiUtils';
import { GuideFormData } from '@/models/Mantenedores';
import { cleanSource, rutCleaner, rutFormatter, validateForm } from '@/utils';
import UIModal from '@/components/UI/UIModal.vue';
import UIButton from '@/components/UI/UIButton.vue';
import { closeToast, showToast } from '@/hooks/useToast';
import { useRoute, useRouter } from 'vue-router';
import { userCanchaState, userState } from '@/store/user.store';
import UITextSuggestions from '@/components/UI/UITextSuggestions.vue';
import UIDateTime from '@/components/UI/UIDateTime.vue';
export default defineComponent({
  name: 'CreateGuide',
  components: {
    'ui-text-input': UITextInput,
    'ui-text-select': UITextDropdown,
    'ui-textarea': UITextarea,
    'ui-modal': UIModal,
    'ui-button': UIButton,
    'ui-text-suggestions': UITextSuggestions,
    'ui-date-time': UIDateTime
  },
  setup() {
    const modalState = ref(false);
    const modalMode = ref('RESET');
    const router = useRouter();
    const route = useRoute();
    const mounted = ref(false);
    const isEditing = ref(false);

    const datePickerState = ref(false);

    const showModal = (mode: 'RESET' | 'EXIT') => {
      modalMode.value = mode;
      modalState.value = true;
    };
    const confirmedModalAction = () => {
      if (modalMode.value === 'EXIT') {
        modalState.value = false;
        router.push({ name: 'guides.list' });
      } else if (modalMode.value === 'RESET') {
        resetFormData();
        modalState.value = false;
      } else {
        modalState.value = false;
      }
    };

    // selects refs
    const movimientoRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const serieRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const especieRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const productoRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const rumaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const comunaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const cortezaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const unidadMedidaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const zonaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const intervencionRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const procedenciaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const origenRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const destinoRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    const tipoAjusteRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    // inputs refs
    const descZonaRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const guiaAraucoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const fechaGuiaRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const guiaProveedorRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const rutProveedorRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const nombreProveedorRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const rolRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const rutConductorRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const nombreConductorRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const patenteCamionRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const patenteCarroRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const largoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const pesoBrutoRecepcionRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const pesoBrutoDespachoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const pesoNetoRecepcionRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const pesoNetoDespachoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const volumenRecepcionRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const volumenDespachoRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const gruaRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const anioPlantacionRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const fechaCortaRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const origenCustomRef = ref<ComponentPublicInstance<typeof UITextSuggestions> | null>(null);
    const fotoEntradaRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const destinoCustomRef = ref<ComponentPublicInstance<typeof UITextSuggestions> | null>(null);
    const fotoSalidaRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const cantidadRollizoEntradaRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const cantidadRollizoSalidaRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const guiaAnexasRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const valorAjusteRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const valorAjusteM3Ref = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const valorAjusteMRRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const observacionesRef = ref<ComponentPublicInstance<typeof UITextInput> | null>(null);
    const estadoMaderaRef = ref<ComponentPublicInstance<typeof UITextDropdown> | null>(null);
    //
    const user = userState;

    const movimientos = [
      { label: 'COMPRA', id: 1 },
      { label: 'VENTA', id: 2 },
      { label: 'DESPACHO', id: 3 },
      { label: 'RECEPCIÓN', id: 4 }
    ];
    user.value?.role === 'ADMINISTRADOR' && movimientos.push({ label: 'AJUSTE', id: 5 });

    const cancha = computed(() => canchaState.value);

    const estadoMadera = estadoMaderasState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));

    const formData = reactive<GuideFormData>({
      createdAt: 0,
      movimiento: 0,
      guiaArauco: '',
      fechaGuia: '',
      serie: '',
      especie: '',
      producto: '',
      guiaProveedor: '',
      ruma: '',
      rutProveedor: '',
      nombreProveedor: '',
      comuna: '',
      rol: '',
      rutConductor: '',
      nombreConductor: '',
      patenteCamion: '',
      patenteCarro: '',
      largo: 0,
      factorCorteza: '',
      pesoBrutoRecepcion: 0,
      pesoBrutoDespacho: 0,
      pesoNetoRecepcion: 0,
      pesoNetoDespacho: 0,
      volumenRecepcion: 0,
      volumenDespacho: 0,
      unidadMedida: '60dff1dcba0ccfd21a8e0525',
      grua: '',
      zona: '',
      anioPlantacion: '',
      fechaCorta: '',
      intervencion: '',
      procedencia: '',
      origen: '',
      origenCustom: '',
      destinoCustom: '',
      destino: '',
      fotoEntrada: 0,
      fotoSalida: 0,
      cantidadRollizoEntrada: 0,
      cantidadRollizoSalida: 0,
      guiasAnexas: '',
      observaciones: '',
      tipoAjuste: '',
      valorAjuste: 0,
      valorAjusteM3: 0,
      valorAjusteMR: 0,
      ingresoPlanta: '',
      ingresoRomana: '',
      salida: '',
      estadoMadera: ''
    });
    const resetFormData = () => {
      formData.createdAt = 0;
      formData.movimiento = undefined;
      formData.guiaArauco = undefined;
      formData.fechaGuia = undefined;
      formData.serie = undefined;
      formData.especie = undefined;
      formData.producto = undefined;
      formData.guiaProveedor = undefined;
      formData.ruma = undefined;
      formData.rutProveedor = undefined;
      formData.nombreProveedor = '';
      formData.comuna = undefined;
      formData.rol = undefined;
      formData.rutConductor = undefined;
      formData.nombreConductor = undefined;
      formData.patenteCamion = undefined;
      formData.patenteCarro = undefined;
      formData.largo = 0;
      formData.factorCorteza = undefined;
      formData.pesoBrutoRecepcion = 0;
      formData.pesoBrutoDespacho = 0;
      formData.pesoNetoRecepcion = 0;
      formData.pesoNetoDespacho = 0;
      formData.volumenRecepcion = 0;
      formData.volumenDespacho = 0;
      formData.unidadMedida = '60dff1dcba0ccfd21a8e0525';
      formData.grua = undefined;
      formData.zona = undefined;
      formData.anioPlantacion = undefined;
      formData.fechaCorta = undefined;
      formData.intervencion = undefined;
      formData.procedencia = undefined;
      formData.origen = undefined;
      formData.origenCustom = '';
      formData.destino = undefined;
      formData.destinoCustom = '';
      formData.fotoEntrada = 0;
      formData.fotoSalida = 0;
      formData.cantidadRollizoEntrada = 0;
      formData.cantidadRollizoSalida = 0;
      formData.guiasAnexas = undefined;
      formData.observaciones = undefined;
      formData.tipoAjuste = undefined;
      formData.valorAjuste = 0;
      formData.valorAjusteM3 = 0;
      formData.valorAjusteMR = 0;
      formData.ingresoPlanta = '';
      formData.ingresoRomana = '';
      formData.salida = '';
      formData.estadoMadera = '';
      modalState.value = false;
      movimientoRef.value?.cleanValue();
      serieRef.value?.cleanValue();
      especieRef.value?.cleanValue();
      productoRef.value?.cleanValue();
      rumaRef.value?.cleanValue();
      comunaRef.value?.cleanValue();
      cortezaRef.value?.cleanValue();
      unidadMedidaRef.value?.cleanValue();
      zonaRef.value?.cleanValue();
      intervencionRef.value?.cleanValue();
      procedenciaRef.value?.cleanValue();
      origenRef.value?.cleanValue();
      destinoRef.value?.cleanValue();
      tipoAjusteRef.value?.cleanValue();
      estadoMaderaRef.value?.cleanValue();
      unidadMedidaDefault.value = '60dff1dcba0ccfd21a8e0525';
      errors.value = [];
    };
    const move = computed(() => {
      if (formData.movimiento === null) return 0;
      return formData.movimiento;
    });
    // const setFormProp = (propName: string, value: any) => {
    //   formData[propName as keyof GuideFormData] = value;
    // };

    const series: SelectItem[] = [
      { id: 'SERIE-I', label: 'SERIE-I' },
      { id: 'SERIE-A', label: 'SERIE-A' }
    ];
    const especies = especiesState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const productos = computed(() => {
      return productosState.value.filter((el) => el.especie._id === formData.especie).map((el) => ({ id: el._id, label: el.codigo }));
    });
    const largoDefault = computed(() => {
      const item = productosState.value.find((el) => el._id === formData.producto);
      if (!item) return 0;
      return item.largo;
    });
    watch(
      () => formData.producto,
      (val) => {
        const item = productosState.value.find((el) => el._id === val);
        formData.largo = item?.largo || 0;
      }
    );
    const serieDefault = computed(() => {
      return formData.movimiento !== null && typeof formData.movimiento === 'number' && formData.movimiento === 1 ? 'SERIE-I' : 'SERIE-A';
    });
    const cortezas = factorCortezaState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const cortezaDefault = computed(() => {
      const item = productosState.value.find((el) => el._id === formData.producto);
      if (!item) return '';
      return item.factorCorteza._id;
    });
    const unidadMedidas = unidadMedidasState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const intervencions = intervencionesState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    const procedencias = procedenciasState.value.map((el) => ({
      id: el._id,
      label: el.descripcion
    }));
    // const procedenciaDefault = computed(() => {
    //   return formData.movimiento !== null && typeof formData.movimiento === 'number' && formData.movimiento === 1 ? '60dff007ba0ccfd21a8e0506' : '60dff01eba0ccfd21a8e0507';
    // });
      const procedenciaDefault = ref('');


    const fechaCortaBefore31 = computed(() => {
      if (formData.fechaCorta?.length === 10 && moment(formData.fechaCorta, 'DD-MM-YYYY').isValid()) {
        const now = +moment().format('x');
        const date = +moment(formData.fechaCorta, 'DD-MM-YYYY').format('x');
        const limit = moment(now).subtract(31, 'days').format();
        return moment(date).isBefore(limit);
      }
      return false;
    });

    const canchas = computed(() => {
      const c = [
        ...cleanSource(
          canchasState.value.map((el) => ({
            id: el._id,
            label: `${el.codigo} ${el.descripcion}`
          }))
        )
      ];
      return [c.pop(), c.pop(), ...c];
    });
    const zonas = computed(() => zonasState.value.map((el) => ({ id: el._id, label: el.descripcion })));
    const comunas = computed(() => comunasState.value.map((el) => ({ id: el._id, label: el.descripcion })));

    const origenDefault = ref('');
    const destinoDefault = ref('');
    const origenDisabled = ref(false);
    const destinoDisabled = ref(false);
      // { label: 'COMPRA', id: 1 },
      // { label: 'VENTA', id: 2 },
      // { label: 'DESPACHO', id: 3 },
      // { label: 'RECEPCIÓN', id: 4 }
    watch(
      () => move.value,
      async () => {
        const { id: guiaId } = route.query;
        const today = moment().format('DD-MM-YYYY');
        formData.fechaGuia = today;
        const userCancha = userCanchaState;
        console.log(move.value);
        if (move.value === 1) {
            procedenciaDefault.value = '60dff007ba0ccfd21a8e0506';
            formData.procedencia = '60dff007ba0ccfd21a8e0506';
        } else  {
            procedenciaDefault.value = '';
            formData.procedencia = '';
        }
        if (move.value === 1 && userCanchaState.value !== 'ALL') {
          // compra
          origenDisabled.value = true;
          destinoDisabled.value = true;
          origenDefault.value = '60e90ca70351b55538c40e45';
          destinoDefault.value = userCancha.value ? userCancha.value : '';


        } else if (move.value === 4 && userCanchaState.value !== 'ALL') {
          origenDisabled.value = false;
          destinoDisabled.value = true;
          origenDefault.value = '';
          destinoDefault.value = userCancha.value ? userCancha.value : '';
          // destinoCustomRef.value && (destinoDefault.value = '60e90cf10351b55538c40e46');
        } else if (move.value === 2 || (move.value === 3 && userCanchaState.value !== 'ALL')) {
          origenDisabled.value = true;
          destinoDisabled.value = false;
          origenDefault.value = userCancha.value ? userCancha.value : '';
          destinoDefault.value = '';
        }
        // 
        await nextTick();
        const inputs = [...document.getElementsByTagName('input')];
        function nextInput(evt: any, actual: number) {
          if (evt.code === 'Enter' || evt.code === 'Intro' || evt.code === 'NumpadEnter') {
            if (actual+1 === inputs.length) {
              console.log('LAST INPUT !');
              const txtarea = document.getElementsByTagName('textarea');
              txtarea[0].focus();
              return;
            }
            const next1 = inputs[actual+1];
            if (next1 && !next1.disabled) next1.focus();
            const next2 = inputs[actual+2];
            if (next1.disabled && next2 && !next2.disabled) next2.focus();
            const next3 = inputs[actual+3];
            if (next1.disabled && next2.disabled && next3 && !next3.disabled) next3.focus();
          } 
        }
        inputs.forEach((el, i) => {
          el.onkeypress = (evt) => nextInput(evt, i);
        });
      }
    );
    const onUnselectEspecie = () => {
      formData.producto = '';
      formData.largo = undefined;
      productoRef.value?.cleanValue();
      cortezaRef.value?.cleanValue();
    };
    const onUnselectProducto = () => {
      formData.largo = undefined;
      cortezaRef.value?.cleanValue();
    };

    // watch(() => formData.producto, (prev, actual) => {
    //   if (prev !== actual) {
    //     formData.largo = undefined;
    //     cortezaRef.value?.cleanValue();
    //   }
    // });

    const anioPlantacionValidValues = [];
    let year = +moment().format('YYYY');
    anioPlantacionValidValues.push(year.toString());
    for (let i = 0; i < 40; i++) {
      anioPlantacionValidValues.push((year -= 1).toString());
    }
    const rumas = ref<SelectItem[]>([]);

    const tiposAjuste = [
      { id: 'ADD', label: 'AÑADIR' },
      { id: 'REDUCE', label: 'REDUCIR' }
    ];

    const errors = ref<string[]>([]);
    const creating = ref(false);

    const isValidForm = () => {
      let formRefs: Ref[] = [movimientoRef];
      if (formData.movimiento === 5) {
        formRefs = [...formRefs, rumaRef, tipoAjusteRef, valorAjusteRef, anioPlantacionRef, descZonaRef];
        return validateForm(formRefs);
      }
      if (formData.movimiento === 1) {
        formRefs = [...formRefs, guiaProveedorRef, nombreProveedorRef, rutProveedorRef, comunaRef, rolRef];
      }
      if (formData.movimiento === 4) {
        formRefs = [...formRefs, pesoBrutoRecepcionRef, pesoNetoRecepcionRef, volumenRecepcionRef, cantidadRollizoEntradaRef];
      }
      if (formData.movimiento === 2 || formData.movimiento === 3) {
        formRefs = [...formRefs, pesoBrutoDespachoRef, pesoNetoDespachoRef, volumenDespachoRef, cantidadRollizoSalidaRef];
      }
      if (formData.movimiento && [1, 2, 3, 4].includes(formData.movimiento)) {
        formRefs = [
          ...formRefs,
          guiaAraucoRef,
          fechaGuiaRef,
          serieRef,
          especieRef,
          productoRef,
          rumaRef,
          rutConductorRef,
          nombreConductorRef,
          patenteCamionRef,
          largoRef,
          cortezaRef,
          unidadMedidaRef,
          anioPlantacionRef,
          fechaCortaRef,
          intervencionRef,
          procedenciaRef,
          origenRef,
          destinoRef,
          zonaRef
        ];
        formData.origen === '60e90cf10351b55538c40e46' && formRefs.push(origenCustomRef);
        formData.destino === '60e90cf10351b55538c40e46' && formRefs.push(destinoCustomRef);
      }
      return validateForm(formRefs);
    };

    const createGuia = async () => {
      const url = `${formData.movimiento !== undefined && formData.movimiento === 5 ? '/guia-ajuste' : '/guia'}`;
      try {
        if (!isValidForm()) return;
        creating.value = true;
        const params = {
          method: guideToEdit.value ? METHODS.PATCH : METHODS.POST,
          url: guideToEdit.value ? `/guia/${guideToEdit.value}` : url,
          auth: true,
          body: cleanSource(formData)
        };
        const request = await makeRequest<any>(params.method, params.url, params.auth, params.body);
        if (request.ok) {
          showToast('SUCCESS', 'Guía creada exitosamente');
          router.replace({ name: 'guides.list' });
          errors.value = [];
        } else {
          showToast('ERROR', 'Error en proceso de creación');
          request.errors && (errors.value = request.errors.split(','));
        }
      } catch (error) {
        console.log(error);
        showToast('ERROR', 'ERROR !');
      } finally {
        creating.value = false;
      }
    };

    const guideToEdit = ref('');

    const movimientoDefault = ref('');
    const zonaDefault = ref('');
    const comunaDefault = ref('');
    const especieDefault = ref('');
    const productoDefault = ref('');
    const rumaDefault = ref('');
    const intervencionDefault = ref('');
    const unidadMedidaDefault = ref('60dff1dcba0ccfd21a8e0525');

    if (route.query.id) showToast('INFO', 'Cargando Guía', false);

    onMounted(async () => {
      const rumasRequest = await makeRequest<{
        total: number;
        items: any[];
      }>(METHODS.GET, '/rumas-simple', true, {}, { qty: 0 });
      if (rumasRequest.ok && rumasRequest.payload?.items) {
        const payload = rumasRequest.payload.items.filter(el => el.estadoRuma._id === '60dfe7c9ba0ccfd21a8e04f3').map((el) => ({
          id: el._id,
          numero: el.numero,
          label: `${el.numero} - ${el.cancha.codigo} ${el.cancha.descripcion}`,
          cancha: el.cancha._id
        }));
        rumas.value = (userState.value?.cancha === 'ALL' ? payload : payload.filter((el) => el?.cancha === userState.value?.cancha)).sort((a, b) => a.numero - b.numero);
      }
      //
      const { id: guiaId } = route.query;
      // if (!guiaId) {
      //    procedenciaDefault.value = formData.movimiento !== null && typeof formData.movimiento === 'number' && formData.movimiento === 1 ? '60dff007ba0ccfd21a8e0506' : '60dff01eba0ccfd21a8e0507';
      // }
      if (guiaId) {
        isEditing.value = true;
        const request = await makeRequest<any>(METHODS.GET, `guia/${guiaId}`, true);
        if (request.ok) {
          mounted.value = true;
          await nextTick();
          guideToEdit.value = request.payload._id;
          const { payload } = request;
          formData.movimiento = payload.movimiento;
          await nextTick();
          setTimeout(() => {
            movimientoDefault.value = payload.movimiento;
            formData.createdAt = payload.createdAt;
            formData.guiaArauco = payload.guiaArauco;
            formData.fechaGuia = payload.fechaGuia;
            formData.serie = payload.serie;
            formData.especie = payload.especie;
            especieDefault.value = payload.especie;
            formData.producto = payload.producto;
            productoDefault.value = payload.producto;
            formData.guiaProveedor = payload.guiaProveedor;
            formData.ruma = payload.ruma;
            rumaDefault.value = payload.ruma;
            formData.rutProveedor = payload.rutProveedor && (payload.rutProveedor = rutCleaner(rutFormatter(payload.rutProveedor)));
            formData.nombreProveedor = payload.nombreProveedor;
            formData.comuna = payload.comuna;
            comunaDefault.value = payload.comuna;
            formData.rol = payload.rol;
            formData.rutConductor = payload.rutConductor && (payload.rutConductor = rutCleaner(rutFormatter(payload.rutConductor)));
            formData.nombreConductor = payload.nombreConductor;
            formData.patenteCamion = payload.patenteCamion;
            formData.patenteCarro = payload.patenteCarro;
            //formData.largo = payload.largo;
            //formData.factorCorteza = payload.factorCorteza;
            formData.pesoBrutoRecepcion = payload.pesoBrutoRecepcion;
            formData.pesoBrutoDespacho = payload.pesoBrutoDespacho;
            formData.pesoNetoRecepcion = payload.pesoNetoRecepcion;
            formData.pesoNetoDespacho = payload.pesoNetoDespacho;
            formData.volumenRecepcion = payload.volumenRecepcion;
            formData.volumenDespacho = payload.volumenDespacho;
            formData.unidadMedida = payload.unidadMedida;
            unidadMedidaDefault.value = payload.unidadMedida;
            formData.grua = payload.grua;
            formData.zona = payload.zona;
            zonaDefault.value = payload.zona;
            formData.anioPlantacion = payload.anioPlantacion;
            formData.fechaCorta = payload.fechaCorta;
            formData.intervencion = payload.intervencion;
            intervencionDefault.value = payload.intervencion;
            formData.procedencia = payload.procedencia;
            procedenciaDefault.value = payload.procedencia;
            formData.origen = payload.origen;
            origenDefault.value = payload.origen;
            formData.origenCustom = payload.origenCustom;
            formData.destino = payload.destino;
            formData.destinoCustom = payload.destinoCustom;
            destinoDefault.value = payload.destino;
            formData.fotoEntrada = payload.fotoEntrada;
            formData.fotoSalida = payload.fotoSalida;
            formData.cantidadRollizoEntrada = payload.cantidadRollizoEntrada;
            formData.cantidadRollizoSalida = payload.cantidadRollizoSalida;
            formData.guiasAnexas = payload.guiasAnexas;
            formData.observaciones = payload.observaciones;
            formData.ingresoPlanta = payload.ingresoPlanta || '';
            formData.ingresoRomana = payload.ingresoRomana || '';
            formData.salida = payload.salida || '';
            closeToast();
          }, 300);
        }
      }
    });

    type pickerInput = 'ingresoPlanta' | 'ingresoRomana' | 'salida'
    const selectedPicker = ref<pickerInput>('ingresoPlanta');
    const onFocus = (key: pickerInput) => {
      selectedPicker.value = key;
      datePickerState.value = true;
    };
    const cleanDate = () => {
      formData[selectedPicker.value] = '';
      datePickerState.value = false;
    };

    const canCleanDate = computed(() => {
      if (!selectedPicker.value) return false;
      return formData[selectedPicker.value] !== '' && formData[selectedPicker.value] !== '';
    });

    const onSelectedDate = (val: string) => {
      formData[selectedPicker.value] = val;
      datePickerState.value = false;
    };

    const onSelectRuma = (evt: string) => {
      formData.ruma = evt;
      if (move.value !== 5) return;
      const ruma = rumas.value.find(el => el.id === evt);
      if (ruma && canchas.value && canchas.value.length) {
        const rm = ruma as  { label: string; id:string; cancha: string };
        const cancha = (canchas.value as SelectItem[]).find(c => c.id === rm.cancha);
        cancha && (destinoDefault.value = cancha.id as string);
        cancha && (origenDefault.value = cancha.id as string);
      }

    };

    return {
      isEditing,
      movimientos,
      formData,
      move,
      cancha,
      especies,
      productos,
      series,
      serieDefault,
      largoDefault,
      cortezas,
      cortezaDefault,
      unidadMedidas,
      intervencions,
      procedencias,
      procedenciaDefault,
      canchas,
      zonas,
      comunas,
      anioPlantacionValidValues,
      tiposAjuste,
      rumas,
      createGuia,
      modalState,
      resetFormData,
      movimientoRef,
      serieRef,
      especieRef,
      productoRef,
      rumaRef,
      comunaRef,
      cortezaRef,
      unidadMedidaRef,
      zonaRef,
      intervencionRef,
      procedenciaRef,
      origenRef,
      destinoRef,
      tipoAjusteRef,
      guiaAraucoRef,
      fechaGuiaRef,
      guiaProveedorRef,
      rutProveedorRef,
      nombreProveedorRef,
      rolRef,
      rutConductorRef,
      nombreConductorRef,
      patenteCamionRef,
      patenteCarroRef,
      largoRef,
      pesoBrutoRecepcionRef,
      pesoBrutoDespachoRef,
      pesoNetoRecepcionRef,
      pesoNetoDespachoRef,
      volumenRecepcionRef,
      volumenDespachoRef,
      anioPlantacionRef,
      descZonaRef,
      fechaCortaRef,
      origenCustomRef,
      fotoEntradaRef,
      gruaRef,
      fotoSalidaRef,
      cantidadRollizoEntradaRef,
      cantidadRollizoSalidaRef,
      guiaAnexasRef,
      valorAjusteRef,
      observacionesRef,
      destinoCustomRef,
      errors,
      creating,
      origenDefault,
      destinoDefault,
      origenDisabled,
      destinoDisabled,
      movimientoDefault,
      zonaDefault,
      comunaDefault,
      especieDefault,
      productoDefault,
      rumaDefault,
      intervencionDefault,
      guideToEdit,
      onUnselectEspecie,
      onUnselectProducto,
      modalMode,
      showModal,
      confirmedModalAction,
      fechaCortaBefore31,
      user,
      unidadMedidaDefault,
      valorAjusteM3Ref,
      valorAjusteMRRef,
      onFocus,
      datePickerState,
      onSelectedDate,
      cleanDate,
      canCleanDate,
      onSelectRuma,
      estadoMaderaRef,
      estadoMadera
    };
  }
});
