import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full mt-3 mb-2 text-center text-lg bold text-green-600 bg-green-100 py-1" }
const _hoisted_2 = { class: "flex mt-3 mb-2" }
const _hoisted_3 = { class: "w-2/3 pr-3" }
const _hoisted_4 = { class: "w-1/3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_calendar = _resolveComponent("ui-calendar")
  const _component_ui_time = _resolveComponent("ui-time")
  const _component_ui_button = _resolveComponent("ui-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("p", null, _toDisplayString(_ctx.fullDate), 1)
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_ui_calendar, { onSelected: _ctx.onSelectDate }, null, 8, ["onSelected"])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_ui_time, { onSelected: _ctx.onSelectedTime }, null, 8, ["onSelected"])
      ])
    ]),
    _createVNode(_component_ui_button, {
      label: "Seleccionar",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('selected', _ctx.fullDate)))
    }),
    (_ctx.canCleanDate)
      ? (_openBlock(), _createBlock(_component_ui_button, {
          key: 0,
          label: "Limpiar",
          color: "selected",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('clean')))
        }))
      : _createCommentVNode("", true)
  ], 64))
}