import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "bg-red-500 text-white text-xs text-right absolute px-2 font-light absolute top-0 right-0 pointer-events-none rounded-bl-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: `rounded-sm px-1 py-1 flex-grow flex items-center flex-col ring-2 ${_ctx.ring} relative ${ _ctx.disabled ? 'bg-gray-200 pointer-events-none': 'bg-white' }`
  }, [
    _createVNode("p", _hoisted_1, _toDisplayString(_ctx.errorMessage), 1),
    (_ctx.value)
      ? (_openBlock(), _createBlock("p", {
          key: 0,
          class: `absolute -top-2 left-0 ${_ctx.labelColor} text-white text-xs px-2 rounded-md shadow-xs`
        }, _toDisplayString(_ctx.placeholder), 3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode("textarea", {
      ref: "areaRef",
      class: "px-1 w-full overflow-hidden text-gray-500 disabled:bg-gray-200 outline-none ring-0",
      placeholder: _ctx.placeholder,
      style: `height:${_ctx.areaHeight}px`,
      value: _ctx.value,
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocus = true)),
      onInput: _cache[2] || (_cache[2] = (event) => _ctx.$emit('update:value', event.target.value)),
      onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      tabindex: "9"
    }, null, 44, ["placeholder", "value"])
  ], 2))
}